.global-buttons {
  position: fixed;
  right: 20px; /* Adjust as needed */
  bottom: 20px; /* Placed at the bottom right */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 9999;
  animation: moveButtons 25s infinite ease-in-out;
}
