@keyframes border-lighting {
    0% {
      border-color: #fc3103; /* Light Yellow */
      box-shadow: 0 0 10px #fc6e01; /* Light Yellow Glow */
    }
    50% {
      border-color: #ff0099; /* Bright Orange */
      box-shadow: 0 0 20px #ff007b; /* Bright Orange Glow */
    }
    100% {
      border-color: #9003fc; /* Light Yellow */
      box-shadow: 0 0 10px #5203fc; /* Light Yellow Glow */
    }
  }
  
  .animate-border-lighting {
    animation: border-lighting 2s infinite;
  }
  